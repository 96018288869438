import gsap from "gsap";
import imagesLoaded from 'imagesloaded';
import Scrollbar, {ScrollbarPlugin} from "smooth-scrollbar";

class DisableScrollPlugin extends ScrollbarPlugin{
    static pluginName = "disableScroll";
    static defaultOptions = {
        direction:"",
    };
    transformDelta(delta){
        if(this.options.direction){
            delta[this.options.direction] = 0;
        }
        return {...delta};
    }
}

Scrollbar.use(DisableScrollPlugin);


class AnchorPlugin extends ScrollbarPlugin {
    static pluginName = 'anchor';
  
    onHashChange = () => {
      this.jumpToHash(window.location.hash);
    };
  
    onClick = (event) => {
      const { target } = event;
  
      if (target.tagName !== 'A') {
        return;
      }
  
      const hash = target.getAttribute('href');
  
      if (!hash || hash.charAt(0) !== '#') {
        return;
      }
  
      this.jumpToHash(hash);
    };
  
    jumpToHash = (hash) => {
      const { scrollbar } = this;
  
      if (!hash) {
        return;
      }    
  
      // reset scrollTop
      scrollbar.containerEl.scrollTop = 0;
  
      scrollbar.scrollIntoView(document.querySelector(hash));
    };
  
    onInit() {
      this.jumpToHash(window.location.hash);
  
      window.addEventListener('hashchange', this.onHashChange);
  
      this.scrollbar.contentEl.addEventListener('click', this.onClick);
    }
  
    onDestory() {
      window.removeEventListener('hashchange', this.onHashChange);
  
      this.scrollbar.contentEl.removeEventListener('click', this.onClick);
    }
  }
  
// usage
Scrollbar.use(AnchorPlugin);

class ModalPlugin extends ScrollbarPlugin {
    static pluginName = 'modal';
  
    static defaultOptions = {
      open: false,
    };
  
    transformDelta(delta) {
      return this.options.open ? { x: 0, y: 0 } : delta;
    }
  }
  
Scrollbar.use(ModalPlugin);

let options = {
    damping: .1,
    alwaysShowTracks: true,
    plugins:{
        disableScroll:{
            direction:"x",
        },
    },
}
// inizializzazione della scrollbar
let pageSmoothScroll = Scrollbar.init(document.body, options);
pageSmoothScroll.track.xAxis.element.remove();
// inizializza la scrollbar come 'bloccata'
pageSmoothScroll.updatePluginOptions('modal', { open: true });


const bar = document.querySelector(".loading__bar--inner");
const counter_num = document.querySelector(".loading__counter--number");
let c = 0;
let barInterval = setInterval(()=>{
    bar.style.width = c+"%";
    counter_num.innerText = c+ "%";
    c++;
    if(c >100){
        clearInterval(barInterval);
        // animations for loading bar
        gsap.to(".loading__bar,.loading__counter,.loading__text",{
            duration:.5,
            opacity:0
        });
        gsap.to(".loading__box",{
            duration:.75,
            height:"500px",
            borderRadius:"50%"
        });
        gsap.to(".loading__box",{
            duration:6,
            delay:.75,
            rotate: "360deg"
        });
        gsap.to(".loading__box",{
            delay:.75,
            duration:.5,
            height:"100%",
            width:"50%",
            borderRadius:"50%"
        });
        
        imagesLoaded(document.querySelectorAll('img'),()=>{
            gsap.to(".loading",{
                delay:.75,
                duration: .75,
                zIndex:1,
                
                background:"transparent",
                opacity:0
            });
            /* Animations for landing components ingress in the page*/
            gsap.to("header",{
                duration:1,
                delay:.75,
                top:"1rem"
            });
            gsap.to(".socials",{
                duration:1,
                delay:1.25,
                bottom:"10rem"
            });
            gsap.to(".scrollDown",{
                duration:1,
                delay:1.75,
                bottom:"3rem"
            });
            
            // attendi 2s e poi sblocca la scrollbar
            setTimeout(()=>{
                pageSmoothScroll.updatePluginOptions('modal', { open: false });
            },2000);
        });
    }
},20);
// FAQ checkboxs open/close
const questions= [...document.querySelectorAll('.question')];
questions.map((question)=>{
    let q_text = question.querySelector("h3");
    q_text,question.addEventListener('click',()=>{
        questions.filter((q)=>q!==question).map((q)=>q.classList.remove("open"))
        question.classList.toggle("open");
    });
});
